import request from "@/utils/request";

interface PersonObtuary {
    "categoryName": string,
    categoryParentId: string | number
}

export function reload() {
    return {
        // categoryName:'',
        // categoryParentId:''
    }
}

const baseUrl = "PersonObtuary/";
const personobituaryApi = {


    //列表
    list(current:number,data:any) {
        return request.post(`${baseUrl}GetObituaryPage`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions":data
        })
    },
    //详情
    detail(id:string) {
        return request.get(`${baseUrl}GeObiturayDetail?id=` + id)
    },
    //讣告视图详情
    view(id:string){
        return request.get(`${baseUrl}GeObiturayDetail?obituaryId=` + id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type:number, data:any) {
        return request[type ? "put" : "post"](baseUrl + (type ? 'UpdateObituary' : 'CreateObituary'), data)
    },
    //删除
    del(id:string) {
        return request.del(`${baseUrl}RemoveObituary?id=` + id)
    },
    //获取讣告背景音乐
    getBackGroundMusicList(){
        return request.post(`${baseUrl}GetBackGroundMusicList`,{})
    },
    //转发讣告
    share(id:string){
        return request.get(`${baseUrl}ObituaryShare?obituaryId=` + id)
    },
    //浏览讣告统计
    obituaryView(id:string){
        return request.get(`${baseUrl}ObituaryView?obituaryId=` + id)
    },
    //浏览讣告数据
    getObiturayView(id:string){
        return request.get(`${baseUrl}GetObiturayView?obituaryId=` + id)
    },
    /**
     客户领取讣告
     "id": 0,
     "obituaryId": 0,
     "merchantId": 0,
     "wechatUserId": 0,
     "receivePhone": "string",
     "createTime": "2024-07-17T10:52:34.175Z",
     "merchantCustomerId": 0
     **/
    receiveObituary(data: any) {
        return request.post(`${baseUrl}ReceiveObituary`, data)
    },
    // 讣告详情商家信息
    getObituaryMerchant(obituaryId: string) {
        return request.get(`${baseUrl}GetObituaryMerchant?obituaryId=`+obituaryId)
    },
    //发布讣告留言
    createObituaryMessage(data: any) {
        return request.post(`${baseUrl}CreateObituaryMessage`, data)
    },
    //讣告详情留言列表
    getObituaryMessge(current: number,obituaryId:string) {
        return request.post(`${baseUrl}GetObituaryMessge`, {
            "orderByFiled": "Id",
            "isAsc": true,
            "offset":current,
            "limit": 20,
            "queryOptions": {
                obituaryId
            }
        })
    },
    //讣告留言列表 不分页
    getObituaryMessgeList(obituaryId:string){
        return request.post(`${baseUrl}GetObituaryMessgeList`, {
            obituaryId
        })
    },
    //获取讣告背景音乐列表
    getBackgroundMusicList(){
        return request.get(`${baseUrl}GetBackgroundMusicList` )
    },
    //获取背景音乐
    getBackgroundMusic(id:string){
        return request.get(`${baseUrl}GetBackgroundMusic?id=${id}`  )
    },
    //讣告开关
    /**
     * "id": "string",
     * "isrelease": true
     */
    updateSysObituaryRelease(data:any){
        return request.post(`${baseUrl}UpdateSysObituaryRelease`,data)
    },
}
export default personobituaryApi;