import request from "@/utils/request";

interface Employee{
    "id": string,
    // categoryParentId:string|number
}

export function reload() {
    return {
        // questionTitle:'',
        // categoryParentId:''
    }
}

const baseUrl="Employee/";
const merchantEmployeeApi={
    //列表
    list(pageData: any,params:Employee){
        return request.post(`${baseUrl}GetEmployeePage`,{
            "offset": pageData.current,
            "limit": pageData.size,
            "orderByFiled": "Id",
            "isAsc": true,
            queryOptions: {...params}
        })
    },
    //详情
    detail(id:string|number){
        return request.get(`${baseUrl}GetEmployeeDetail?id=`+id)
    },
    //更改  0 新增 1 编辑
    updateOrAdd(type: number, data: any) {
        return request[type ?"put":"post"](baseUrl + (type ? 'UpdateEmployee' : 'CreateEmployee'), data)
    },
    //删除
    del(id:string|number){
        return request.del(`${baseUrl}RemoveEmployee?id=`+id)
    }

}
export default  merchantEmployeeApi;